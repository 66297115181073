<template>
  <div class="BaseDeliveryRequirements">
    <div class="row row--justify-between">
      <label class="BaseDeliveryRequirements__heading fs-18 fw-med mb-13">
        Delivery Options
      </label>
      <button
        @click="nowEditing"
        @keydown.enter="nowEditing"
        class="fc-light fs-14"
        data-cy="base-delivery-requirements-edit-btn"
      >
        Edit
      </button>
    </div>

    <!-- EXTERNAL VALIDATION -->
    <div
      v-if="validationInstructions"
      class="mb-12"
    >
      <label class="fc-red fs-12 uppercase">
        {{ validationInstructions }}
      </label>
    </div>

    <!-- BASE -->
    <div
      v-if="!editing"
      class="DebtorProfileDelivery__base column"
    >
      <div
        v-if="duplicateMethods.noa_emails.value"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">NOA EMAILS</label>
        <label class="fc-mid fs-14">
          {{ duplicateMethods.noa_emails.value }}
        </label>
      </div>

      <div
        v-if="duplicateMethods.pay_status_emails.value"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">PAYMENT STATUS EMAILS</label>
        <label class="fc-mid fs-14">{{ duplicateMethods.pay_status_emails.value }}</label>
      </div>

      <div
        v-if="duplicateMethods.invoice_delivery_emails.required"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">INVOICE DELIVERY EMAILS</label>
        <label class="fc-mid fs-14">{{ duplicateMethods.invoice_delivery_emails.value }}</label>
      </div>

      <div
        v-if="duplicateMethods.fax.required"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">FAX</label>
        <label class="fc-mid fs-14">{{ duplicateMethods.fax.value }}</label>
      </div>

      <div
        v-if="duplicateMethods.mail.required.copies"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">MAIL - COPIES</label>
        <div class="column">
          <label class="fc-mid fs-14 no-wrap">
            <!-- eslint-disable-next-line max-len -->
            {{ duplicateMethods.mail.value.address }}{{ duplicateMethods.mail.value.address_2 ? `, ${duplicateMethods.mail.value.address_2}` : `,` }}
          </label>
          <label class="fc-mid fs-14 no-wrap">
            <!-- eslint-disable-next-line max-len -->
            {{ duplicateMethods.mail.value.city }}, {{ duplicateMethods.mail.value.state }} {{ duplicateMethods.mail.value.zip }}
          </label>
        </div>
      </div>

      <div
        v-if="duplicateMethods.mail.required.originals"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">MAIL - ORIGINALS</label>
        <div class="column">
          <label class="fc-mid fs-14 no-wrap">
            <!-- eslint-disable-next-line max-len -->
            {{ duplicateMethods.mail.value.address }}{{ duplicateMethods.mail.value.address_2 ? `, ${duplicateMethods.mail.value.address_2}` : `,` }}
          </label>
          <label class="fc-mid fs-14 no-wrap">
            <!-- eslint-disable-next-line max-len -->
            {{ duplicateMethods.mail.value.city }}, {{ duplicateMethods.mail.value.state }} {{ duplicateMethods.mail.value.zip }}
          </label>
        </div>
      </div>

      <div
        v-if="duplicateMethods.online.required"
        class="column column--width-auto mb-12"
      >
        <label class="fc-light fs-12 mb-7">ONLINE</label>
        <label class="fc-mid fs-14">
          {{ duplicateMethods.online.value }}
        </label>
      </div>
    </div>

    <!-- EDIT -->
    <div
      v-if="editing"
      class="column"
    >
      <!-- NOA EMAILS -->
      <div class="row ml-28 mb-12">
        <base-input
          v-model.trim="duplicateMethods.noa_emails.value"
          data-cy="base-delivery-requirements-noa-emails-input"
          :label="true"
          :label-for="'noa-email-input'"
          :instructions="instructions.NOA_EMAILS_INSTRUCTION"
          :valid="duplicateMethods.noa_emails.valid"
        >
          NOA Emails (separate with ", ")
        </base-input>
      </div>


      <!-- PAY STAUTS EMAILS -->
      <div class="row ml-28 mb-12">
        <base-input
          v-model.trim="duplicateMethods.pay_status_emails.value"
          :label="true"
          :label-for="'pay-status-email-input'"
          :instructions="instructions.PAY_STATUS_EMAILS_INSTRUCTION"
          :valid="duplicateMethods.pay_status_emails.valid"
        >
          Payment Status Emails (separate with ", ")
        </base-input>
      </div>

      <!-- EMAIL -->
      <div class="row mb-12">
        <base-checkbox
          v-model="duplicateMethods.invoice_delivery_emails.required"
          :label-for="'email-checkbox'"
          :starting-value="duplicateMethods.invoice_delivery_emails.required"
        />
        <base-input
          v-model.trim="duplicateMethods.invoice_delivery_emails.value"
          :label="true"
          :label-for="'email-input'"
          :instructions="instructions.INVOICE_DELIVERY_EMAILS_INSTRUCTION"
          :valid="duplicateMethods.invoice_delivery_emails.valid"
        >
          Invoice Delivery Emails (separate with ", ")
        </base-input>
      </div>

      <!-- FAX -->
      <div class="row mb-12">
        <base-checkbox
          v-model="duplicateMethods.fax.required"
          :label-for="'fax-checkbox'"
          :starting-value="duplicateMethods.fax.required"
        />
        <base-input
          v-model.trim="duplicateMethods.fax.value"
          :label="true"
          :label-for="'fax-input'"
          :instructions="instructions.FAX_INSTRUCTION"
          :valid="duplicateMethods.fax.valid"
        >
          Fax
        </base-input>
      </div>

      <!-- COPIES -->
      <label class="fs-14 fw-med mt-6 mb-6 ml-27">Mail Copies</label>
      <div class="row mb-12">
        <base-checkbox
          v-model="duplicateMethods.mail.required.copies"
          :label-for="'copies-checkbox'"
          :starting-value="duplicateMethods.mail.required.copies"
        />
        <div class="row row--wrap">
          <base-input
            v-model.trim="duplicateMethods.mail.value.address"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'address'"
            :instructions="instructions.ADDRESS_INSTRUCTION"
            :placeholder="'Street address'"
            :valid="duplicateMethods.mail.valid.address"
          >
            Address
          </base-input>
          <base-input
            v-model.trim="duplicateMethods.mail.value.address_2"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'address 2'"
            :instructions="instructions.ADDRESS_2_INSTRUCTION"
            :placeholder="'Suite #'"
            :valid="duplicateMethods.mail.valid.address_2"
          >
            Address 2
          </base-input>
          <base-input
            v-model.trim="duplicateMethods.mail.value.city"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'city'"
            :instructions="instructions.CITY_INSTRUCTION"
            :placeholder="'City'"
            :valid="duplicateMethods.mail.valid.city"
          >
            City
          </base-input>
          <div class="BaseDeliveryRequirements__select-container column mb-12">
            <label
              class="BaseInput__label uppercase"
              for="state"
            >
              State
            </label>
            <v-select
              v-model="duplicateMethods.mail.value.state"
              aria-label="Select input to choose state"
              :class="['BaseDeliveryRequirements__select mr-6', {
                'BaseDeliveryRequirements__select--invalid': !duplicateMethods.mail.valid.state
              }]"
              :clearable="false"
              id="state"
              :instructions="instructions.STATE_INSTRUCTION"
              :options="duplicateMethods.options.state"
              :placeholder="'State:'"
            />
          </div>

          <base-input
            v-model.trim="duplicateMethods.mail.value.zip"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'zip'"
            :instructions="instructions.ZIP_INSTRUCTION"
            :placeholder="'Zip'"
            :valid="duplicateMethods.mail.valid.zip"
          >
            Zip
          </base-input>
        </div>
      </div>

      <!-- ORIGINALS -->
      <label class="fs-14 fw-med mt-6 mb-6 ml-27">Mail Originals</label>
      <div class="row mb-12">
        <base-checkbox
          v-model="duplicateMethods.mail.required.originals"
          :label-for="'originals-checkbox'"
          :starting-value="duplicateMethods.mail.required.originals"
        />
        <div class="row row--wrap">
          <base-input
            v-model.trim="duplicateMethods.mail.value.address"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'address'"
            :instructions="instructions.ADDRESS_INSTRUCTION"
            :placeholder="'Street address'"
            :valid="duplicateMethods.mail.valid.address"
          >
            Address
          </base-input>
          <base-input
            v-model.trim="duplicateMethods.mail.value.address_2"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'address 2'"
            :instructions="instructions.ADDRESS_2_INSTRUCTION"
            :placeholder="'Suite #'"
            :valid="duplicateMethods.mail.valid.address_2"
          >
            Address 2
          </base-input>
          <base-input
            v-model.trim="duplicateMethods.mail.value.city"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'city'"
            :instructions="instructions.CITY_INSTRUCTION"
            :placeholder="'City'"
            :valid="duplicateMethods.mail.valid.city"
          >
            City
          </base-input>
          <div class="BaseDeliveryRequirements__select-container column mb-12">
            <label
              class="BaseInput__label uppercase"
              for="state"
            >
              State
            </label>
            <v-select
              v-model="duplicateMethods.mail.value.state"
              aria-label="Select input to choose state"
              :class="['BaseDeliveryRequirements__select mr-6', {
                'BaseDeliveryRequirements__select--invalid': !duplicateMethods.mail.valid.state
              }]"
              :clearable="false"
              id="state"
              :instructions="instructions.STATE_INSTRUCTION"
              :options="duplicateMethods.options.state"
              :placeholder="'State:'"
            />
          </div>
          <base-input
            v-model.trim="duplicateMethods.mail.value.zip"
            class="mr-5 mb-5"
            :label="true"
            :label-for="'zip'"
            :instructions="instructions.ZIP_INSTRUCTION"
            :placeholder="'Zip'"
            :valid="duplicateMethods.mail.valid.zip"
          >
            Zip
          </base-input>
        </div>
      </div>

      <!-- ONLINE SUBMIT -->
      <div class="row mb-12">
        <base-checkbox
          v-model="duplicateMethods.online.required"
          :label-for="'online-checkbox'"
          :starting-value="duplicateMethods.online.required"
        />
        <base-input
          v-model.trim="duplicateMethods.online.value"
          :label="true"
          :label-for="'online-input'"
          :instructions="instructions.ONLINE_SUBMIT_URL_INSTRUCTION"
          :valid="duplicateMethods.online.valid"
        >
          Online
        </base-input>
      </div>

      <!-- BUTTONS -->
      <div class="row row--align-center">
        <base-button
          @click="emitDeliveryReqs"
          @keydown.enter="emitDeliveryReqs"
          class="bg-blue fc-white mr-6"
          data-cy="base-delivery-requirements-save-btn"
        >
          Save
        </base-button>
        <base-button
          @click="cancel"
          @keydown.enter="cancel"
          class="bg-light fc-white mr-6"
          data-cy="base-delivery-requirements-cancel-btn"
          :disabled="forceEdit"
        >
          Cancel
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import { statesAbbreviations } from '../utils/constants'
// Components
import BaseButton from './base-button.vue'
import BaseCheckbox from './base-checkbox.vue'
import BaseInput from './base-input.vue'
// Mixins
import {
  ValidationMixin
} from '../utils/validation-mixin'

export default {
  name: 'BaseDeliveryRequirements',

  components: {
    BaseButton,
    BaseCheckbox,
    BaseInput,
  },

  mixins: [ValidationMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    },
    forceEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    validationInstructions: {
      type: String,
      required: false,
      default: ''
    }
  },

  created () {
    this.configureDuplicateMethods()
    if (this.forceEdit) this.editing = true
  },

  data () {
    return {
      duplicateMethods: null,
      editing: false,
    }
  },

  watch: {
    // When updating noa or invoice delivery emails, this will watch for when the debtor info
    // is updated in the parent component, then re-form the email in the input(s) to be properly
    // split with ', ' when more than one email is present per field
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1781
    debtor () {
      this.configureDuplicateMethods()
    },

    $route (newRoute, oldRoute) {
      // When changing between debtors using universal search, each component needs it's
      // data reset
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/2082
      if (newRoute.params.id !== oldRoute.params.id) {
        this.dataReset()
        this.configureDuplicateMethods()
      }
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.configureDuplicateMethods()
    },

    configureDuplicateMethods () {
      this.duplicateMethods = {
        invoice_delivery_emails: {
          required: this.debtor.require_email,
          valid: true,
          value: this.debtor.invoice_delivery_emails
            ? this.debtor.invoice_delivery_emails.join(', ')
            : '',
        },
        noa_emails: {
          valid: true,
          value: this.debtor.noa_emails ? this.debtor.noa_emails.join(', ') : ''
        },
        fax: {
          required: this.debtor.require_fax,
          valid: true,
          value: this.debtor.fax,
        },
        mail: {
          required: {
            copies: this.debtor.require_copies,
            originals: this.debtor.require_originals,
          },
          valid: {
            address: true,
            address_2: true,
            city: true,
            state: true,
            zip: true,
          },
          value: {
            address: this.debtor.mailing_address,
            address_2: this.debtor.mailing_address_2,
            city: this.debtor.mailing_city,
            state: this.debtor.mailing_state,
            zip: this.debtor.mailing_zip,
          },
        },
        online: {
          required: this.debtor.require_online_submit,
          valid: true,
          value: this.debtor.online_submit_url,
        },
        options: {
          state: statesAbbreviations
        },
        pay_status_emails: {
          value: this.debtor.pay_status_emails ? this.debtor.pay_status_emails.join(', ') : '',
          valid: true
        }
      }
    },

    dataReset () {
      Object.assign(this.$data, this.$options.data())
    },

    nowEditing () {
      this.configureDuplicateMethods()
      this.editing = true
    },

    emitDeliveryReqs () {
      if (!this.validation()) return

      this.$emit('save', this.duplicateMethods)

      this.editing = false
    },

    validation () {
      const data = this.duplicateMethods
      let valid = true

      // noa emails (REQUIRED, look for a missing existing value)
      // If didn't separate with a comma
      // If each separate email isn't a valid email
      if (!data.noa_emails.value || !this.isValidEmails(data.noa_emails.value)) {
        this.$set(this.duplicateMethods.noa_emails, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateMethods.noa_emails, 'valid', true)
      }

      // payment status email (NOT REQUIRED, look for an existing value)
      // If didn't separate with a comma
      // If each separate email isn't a valid email
      if (data.pay_status_emails.value && !this.isValidEmails(data.pay_status_emails.value)) {
        this.$set(this.duplicateMethods.pay_status_emails, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateMethods.pay_status_emails, 'valid', true)
      }

      // invoice delivery email
      // If didn't separate with a comma
      // If each separate email isn't a valid email
      if (
        data.invoice_delivery_emails.required
        && !this.isValidEmails(data.invoice_delivery_emails.value)) {
        this.$set(this.duplicateMethods.invoice_delivery_emails, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateMethods.invoice_delivery_emails, 'valid', true)
      }

      // fax
      if (data.fax.required && !this.isValidPhone(data.fax.value)) {
        this.$set(this.duplicateMethods.fax, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateMethods.fax, 'valid', true)
      }

      // address (for either or both of copies and originals)
      if (data.mail.required.copies || data.mail.required.originals) {
        if (!this.isValidAddress(data.mail.value.address)) {
          this.$set(this.duplicateMethods.mail.valid, 'address', false)
          valid = false
        } else {
          this.$set(this.duplicateMethods.mail.valid, 'address', true)
        }

        if (data.mail.value.address_2) {
          if (!this.isValidAddress(data.mail.value.address_2)) {
            this.$set(this.duplicateMethods.mail.valid, 'address_2', false)
            valid = false
          } else {
            this.$set(this.duplicateMethods.mail.valid, 'address_2', true)
          }
        }

        if (!this.isValidCity(data.mail.value.city)) {
          this.$set(this.duplicateMethods.mail.valid, 'city', false)
          valid = false
        } else {
          this.$set(this.duplicateMethods.mail.valid, 'city', true)
        }

        if (!this.isValidState(data.mail.value.state)) {
          this.$set(this.duplicateMethods.mail.valid, 'state', false)
          valid = false
        } else {
          this.$set(this.duplicateMethods.mail.valid, 'state', true)
        }

        if (!this.isValidZip(data.mail.value.zip)) {
          this.$set(this.duplicateMethods.mail.valid, 'zip', false)
          valid = false
        } else {
          this.$set(this.duplicateMethods.mail.valid, 'zip', true)
        }
      }

      // online submit
      if (data.online.required && !this.isValidURL(data.online.value)) {
        this.$set(this.duplicateMethods.online, 'valid', false)
        valid = false
      } else {
        this.$set(this.duplicateMethods.online, 'valid', true)
      }

      return valid
    },
  },
}
</script>

<style lang="sass">
.BaseDeliveryRequirements
  width: 100%

  // Keeps the checkbox vertically centered with the inputs
  .BaseCheckbox
    height: $input-height
    margin-top: rem(25px)

  &__edit-btn
    height: rem(22px)
    width: rem(35px)

  // This may be overriden in parent component
  &__heading

  &__select
    width: rem(185px)

    &--invalid
      border: $border
      border-color: $danger
      border-radius: $border-radius

  &__select-container
    width: rem(190px)

</style>
