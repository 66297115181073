var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "BaseDeliveryRequirements" }, [
    _c("div", { staticClass: "row row--justify-between" }, [
      _c(
        "label",
        { staticClass: "BaseDeliveryRequirements__heading fs-18 fw-med mb-13" },
        [_vm._v("\n      Delivery Options\n    ")]
      ),
      _c(
        "button",
        {
          staticClass: "fc-light fs-14",
          attrs: { "data-cy": "base-delivery-requirements-edit-btn" },
          on: {
            click: _vm.nowEditing,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.nowEditing($event)
            }
          }
        },
        [_vm._v("\n      Edit\n    ")]
      )
    ]),
    _vm.validationInstructions
      ? _c("div", { staticClass: "mb-12" }, [
          _c("label", { staticClass: "fc-red fs-12 uppercase" }, [
            _vm._v("\n      " + _vm._s(_vm.validationInstructions) + "\n    ")
          ])
        ])
      : _vm._e(),
    !_vm.editing
      ? _c("div", { staticClass: "DebtorProfileDelivery__base column" }, [
          _vm.duplicateMethods.noa_emails.value
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("NOA EMAILS")
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.duplicateMethods.noa_emails.value) +
                      "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.pay_status_emails.value
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("PAYMENT STATUS EMAILS")
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(_vm._s(_vm.duplicateMethods.pay_status_emails.value))
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.invoice_delivery_emails.required
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("INVOICE DELIVERY EMAILS")
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(
                    _vm._s(_vm.duplicateMethods.invoice_delivery_emails.value)
                  )
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.fax.required
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("FAX")
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(_vm._s(_vm.duplicateMethods.fax.value))
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.mail.required.copies
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("MAIL - COPIES")
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fc-mid fs-14 no-wrap" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.duplicateMethods.mail.value.address) +
                        _vm._s(
                          _vm.duplicateMethods.mail.value.address_2
                            ? ", " + _vm.duplicateMethods.mail.value.address_2
                            : ","
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("label", { staticClass: "fc-mid fs-14 no-wrap" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.duplicateMethods.mail.value.city) +
                        ", " +
                        _vm._s(_vm.duplicateMethods.mail.value.state) +
                        " " +
                        _vm._s(_vm.duplicateMethods.mail.value.zip) +
                        "\n        "
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.mail.required.originals
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("MAIL - ORIGINALS")
                ]),
                _c("div", { staticClass: "column" }, [
                  _c("label", { staticClass: "fc-mid fs-14 no-wrap" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.duplicateMethods.mail.value.address) +
                        _vm._s(
                          _vm.duplicateMethods.mail.value.address_2
                            ? ", " + _vm.duplicateMethods.mail.value.address_2
                            : ","
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("label", { staticClass: "fc-mid fs-14 no-wrap" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.duplicateMethods.mail.value.city) +
                        ", " +
                        _vm._s(_vm.duplicateMethods.mail.value.state) +
                        " " +
                        _vm._s(_vm.duplicateMethods.mail.value.zip) +
                        "\n        "
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm.duplicateMethods.online.required
            ? _c("div", { staticClass: "column column--width-auto mb-12" }, [
                _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                  _vm._v("ONLINE")
                ]),
                _c("label", { staticClass: "fc-mid fs-14" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.duplicateMethods.online.value) +
                      "\n      "
                  )
                ])
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.editing
      ? _c("div", { staticClass: "column" }, [
          _c(
            "div",
            { staticClass: "row ml-28 mb-12" },
            [
              _c(
                "base-input",
                {
                  attrs: {
                    "data-cy": "base-delivery-requirements-noa-emails-input",
                    label: true,
                    "label-for": "noa-email-input",
                    instructions: _vm.instructions.NOA_EMAILS_INSTRUCTION,
                    valid: _vm.duplicateMethods.noa_emails.valid
                  },
                  model: {
                    value: _vm.duplicateMethods.noa_emails.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.duplicateMethods.noa_emails,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "duplicateMethods.noa_emails.value"
                  }
                },
                [_vm._v('\n        NOA Emails (separate with ", ")\n      ')]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row ml-28 mb-12" },
            [
              _c(
                "base-input",
                {
                  attrs: {
                    label: true,
                    "label-for": "pay-status-email-input",
                    instructions:
                      _vm.instructions.PAY_STATUS_EMAILS_INSTRUCTION,
                    valid: _vm.duplicateMethods.pay_status_emails.valid
                  },
                  model: {
                    value: _vm.duplicateMethods.pay_status_emails.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.duplicateMethods.pay_status_emails,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "duplicateMethods.pay_status_emails.value"
                  }
                },
                [
                  _vm._v(
                    '\n        Payment Status Emails (separate with ", ")\n      '
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row mb-12" },
            [
              _c("base-checkbox", {
                attrs: {
                  "label-for": "email-checkbox",
                  "starting-value":
                    _vm.duplicateMethods.invoice_delivery_emails.required
                },
                model: {
                  value: _vm.duplicateMethods.invoice_delivery_emails.required,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.duplicateMethods.invoice_delivery_emails,
                      "required",
                      $$v
                    )
                  },
                  expression:
                    "duplicateMethods.invoice_delivery_emails.required"
                }
              }),
              _c(
                "base-input",
                {
                  attrs: {
                    label: true,
                    "label-for": "email-input",
                    instructions:
                      _vm.instructions.INVOICE_DELIVERY_EMAILS_INSTRUCTION,
                    valid: _vm.duplicateMethods.invoice_delivery_emails.valid
                  },
                  model: {
                    value: _vm.duplicateMethods.invoice_delivery_emails.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.duplicateMethods.invoice_delivery_emails,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "duplicateMethods.invoice_delivery_emails.value"
                  }
                },
                [
                  _vm._v(
                    '\n        Invoice Delivery Emails (separate with ", ")\n      '
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row mb-12" },
            [
              _c("base-checkbox", {
                attrs: {
                  "label-for": "fax-checkbox",
                  "starting-value": _vm.duplicateMethods.fax.required
                },
                model: {
                  value: _vm.duplicateMethods.fax.required,
                  callback: function($$v) {
                    _vm.$set(_vm.duplicateMethods.fax, "required", $$v)
                  },
                  expression: "duplicateMethods.fax.required"
                }
              }),
              _c(
                "base-input",
                {
                  attrs: {
                    label: true,
                    "label-for": "fax-input",
                    instructions: _vm.instructions.FAX_INSTRUCTION,
                    valid: _vm.duplicateMethods.fax.valid
                  },
                  model: {
                    value: _vm.duplicateMethods.fax.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.duplicateMethods.fax,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "duplicateMethods.fax.value"
                  }
                },
                [_vm._v("\n        Fax\n      ")]
              )
            ],
            1
          ),
          _c("label", { staticClass: "fs-14 fw-med mt-6 mb-6 ml-27" }, [
            _vm._v("Mail Copies")
          ]),
          _c(
            "div",
            { staticClass: "row mb-12" },
            [
              _c("base-checkbox", {
                attrs: {
                  "label-for": "copies-checkbox",
                  "starting-value": _vm.duplicateMethods.mail.required.copies
                },
                model: {
                  value: _vm.duplicateMethods.mail.required.copies,
                  callback: function($$v) {
                    _vm.$set(_vm.duplicateMethods.mail.required, "copies", $$v)
                  },
                  expression: "duplicateMethods.mail.required.copies"
                }
              }),
              _c(
                "div",
                { staticClass: "row row--wrap" },
                [
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "address",
                        instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                        placeholder: "Street address",
                        valid: _vm.duplicateMethods.mail.valid.address
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.address,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "address",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.address"
                      }
                    },
                    [_vm._v("\n          Address\n        ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "address 2",
                        instructions: _vm.instructions.ADDRESS_2_INSTRUCTION,
                        placeholder: "Suite #",
                        valid: _vm.duplicateMethods.mail.valid.address_2
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.address_2,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "address_2",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.address_2"
                      }
                    },
                    [_vm._v("\n          Address 2\n        ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "city",
                        instructions: _vm.instructions.CITY_INSTRUCTION,
                        placeholder: "City",
                        valid: _vm.duplicateMethods.mail.valid.city
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.city,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "city",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.city"
                      }
                    },
                    [_vm._v("\n          City\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "BaseDeliveryRequirements__select-container column mb-12"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "BaseInput__label uppercase",
                          attrs: { for: "state" }
                        },
                        [_vm._v("\n            State\n          ")]
                      ),
                      _c("v-select", {
                        class: [
                          "BaseDeliveryRequirements__select mr-6",
                          {
                            "BaseDeliveryRequirements__select--invalid": !_vm
                              .duplicateMethods.mail.valid.state
                          }
                        ],
                        attrs: {
                          "aria-label": "Select input to choose state",
                          clearable: false,
                          id: "state",
                          instructions: _vm.instructions.STATE_INSTRUCTION,
                          options: _vm.duplicateMethods.options.state,
                          placeholder: "State:"
                        },
                        model: {
                          value: _vm.duplicateMethods.mail.value.state,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateMethods.mail.value,
                              "state",
                              $$v
                            )
                          },
                          expression: "duplicateMethods.mail.value.state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "zip",
                        instructions: _vm.instructions.ZIP_INSTRUCTION,
                        placeholder: "Zip",
                        valid: _vm.duplicateMethods.mail.valid.zip
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.zip,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "zip",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.zip"
                      }
                    },
                    [_vm._v("\n          Zip\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("label", { staticClass: "fs-14 fw-med mt-6 mb-6 ml-27" }, [
            _vm._v("Mail Originals")
          ]),
          _c(
            "div",
            { staticClass: "row mb-12" },
            [
              _c("base-checkbox", {
                attrs: {
                  "label-for": "originals-checkbox",
                  "starting-value": _vm.duplicateMethods.mail.required.originals
                },
                model: {
                  value: _vm.duplicateMethods.mail.required.originals,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.duplicateMethods.mail.required,
                      "originals",
                      $$v
                    )
                  },
                  expression: "duplicateMethods.mail.required.originals"
                }
              }),
              _c(
                "div",
                { staticClass: "row row--wrap" },
                [
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "address",
                        instructions: _vm.instructions.ADDRESS_INSTRUCTION,
                        placeholder: "Street address",
                        valid: _vm.duplicateMethods.mail.valid.address
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.address,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "address",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.address"
                      }
                    },
                    [_vm._v("\n          Address\n        ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "address 2",
                        instructions: _vm.instructions.ADDRESS_2_INSTRUCTION,
                        placeholder: "Suite #",
                        valid: _vm.duplicateMethods.mail.valid.address_2
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.address_2,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "address_2",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.address_2"
                      }
                    },
                    [_vm._v("\n          Address 2\n        ")]
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "city",
                        instructions: _vm.instructions.CITY_INSTRUCTION,
                        placeholder: "City",
                        valid: _vm.duplicateMethods.mail.valid.city
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.city,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "city",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.city"
                      }
                    },
                    [_vm._v("\n          City\n        ")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "BaseDeliveryRequirements__select-container column mb-12"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "BaseInput__label uppercase",
                          attrs: { for: "state" }
                        },
                        [_vm._v("\n            State\n          ")]
                      ),
                      _c("v-select", {
                        class: [
                          "BaseDeliveryRequirements__select mr-6",
                          {
                            "BaseDeliveryRequirements__select--invalid": !_vm
                              .duplicateMethods.mail.valid.state
                          }
                        ],
                        attrs: {
                          "aria-label": "Select input to choose state",
                          clearable: false,
                          id: "state",
                          instructions: _vm.instructions.STATE_INSTRUCTION,
                          options: _vm.duplicateMethods.options.state,
                          placeholder: "State:"
                        },
                        model: {
                          value: _vm.duplicateMethods.mail.value.state,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.duplicateMethods.mail.value,
                              "state",
                              $$v
                            )
                          },
                          expression: "duplicateMethods.mail.value.state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "base-input",
                    {
                      staticClass: "mr-5 mb-5",
                      attrs: {
                        label: true,
                        "label-for": "zip",
                        instructions: _vm.instructions.ZIP_INSTRUCTION,
                        placeholder: "Zip",
                        valid: _vm.duplicateMethods.mail.valid.zip
                      },
                      model: {
                        value: _vm.duplicateMethods.mail.value.zip,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.duplicateMethods.mail.value,
                            "zip",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "duplicateMethods.mail.value.zip"
                      }
                    },
                    [_vm._v("\n          Zip\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row mb-12" },
            [
              _c("base-checkbox", {
                attrs: {
                  "label-for": "online-checkbox",
                  "starting-value": _vm.duplicateMethods.online.required
                },
                model: {
                  value: _vm.duplicateMethods.online.required,
                  callback: function($$v) {
                    _vm.$set(_vm.duplicateMethods.online, "required", $$v)
                  },
                  expression: "duplicateMethods.online.required"
                }
              }),
              _c(
                "base-input",
                {
                  attrs: {
                    label: true,
                    "label-for": "online-input",
                    instructions:
                      _vm.instructions.ONLINE_SUBMIT_URL_INSTRUCTION,
                    valid: _vm.duplicateMethods.online.valid
                  },
                  model: {
                    value: _vm.duplicateMethods.online.value,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.duplicateMethods.online,
                        "value",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "duplicateMethods.online.value"
                  }
                },
                [_vm._v("\n        Online\n      ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row row--align-center" },
            [
              _c(
                "base-button",
                {
                  staticClass: "bg-blue fc-white mr-6",
                  attrs: { "data-cy": "base-delivery-requirements-save-btn" },
                  on: {
                    click: _vm.emitDeliveryReqs,
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.emitDeliveryReqs($event)
                    }
                  }
                },
                [_vm._v("\n        Save\n      ")]
              ),
              _c(
                "base-button",
                {
                  staticClass: "bg-light fc-white mr-6",
                  attrs: {
                    "data-cy": "base-delivery-requirements-cancel-btn",
                    disabled: _vm.forceEdit
                  },
                  on: {
                    click: _vm.cancel,
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.cancel($event)
                    }
                  }
                },
                [_vm._v("\n        Cancel\n      ")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }